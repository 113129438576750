import React, { useEffect, useRef } from 'react';

import { convertLinebreaksToBr } from '@utils/convertLinebreaksToBr';

import { Container } from './HTMLRenderer.styled';

interface HTMLRendererProps {
  className?: string;
  html: string;
  nl2br?: boolean;
  customAs?: string;
}

const HTMLRenderer = ({
  html,
  className,
  nl2br = false,
  customAs = 'div',
  ...props
}: HTMLRendererProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const links = contentRef.current?.querySelectorAll('a') || [];
    const handleLinkClick = (e: MouseEvent) => {
      const anchor = e.currentTarget as HTMLAnchorElement;

      if (anchor.host !== window.location.host) {
        anchor.target = '_blank';
        anchor.rel = 'noopener noreferrer nofollow';
      }
    };

    links.forEach(link => {
      link.addEventListener('click', handleLinkClick);

      if (link.host === window.location.host) {
        if (link.target) link.removeAttribute('target');

        if (link.rel) link.removeAttribute('rel');
      }
    });

    return () => {
      links.forEach(link => {
        link?.removeEventListener('click', handleLinkClick);
      });
    };
  }, []);

  return (
    <Container
      {...props}
      as={customAs}
      className={className}
      ref={contentRef}
      dangerouslySetInnerHTML={{
        __html: nl2br ? convertLinebreaksToBr(html) : html,
      }}
    />
  );
};

export default HTMLRenderer;
