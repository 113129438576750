import styled, { css } from 'styled-components';

import { centeredGridColumns, getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import Carousel from '@common/components/Carousel';
import { Play } from '@common/components/Icons';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';
import Button, { BUTTON_VARIANTS } from '@common/components/Button';

export const Container = styled(CMSBlockContainer)`
  ${media.from640up`
    grid-column: ${centeredGridColumns(12)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(12)};
  `}
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;

export const CarouselWrapper = styled.div`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'widthBased', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'widthBased', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 2)};
  background-color: ${getThemeColor('bluegrey.97')};

  ${({ shouldRenderCarousel }) =>
    !shouldRenderCarousel &&
    css`
      display: flex;
      gap: 2rem;
      justify-content: center;
    `}
`;

export const StyledCarousel = styled(Carousel)`
  margin-bottom: 0;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
`;

export const VoucherWrapper = styled.button`
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  padding: 0;
  cursor: pointer;

  & > * {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: ${({ theme }) => theme.getFluidAttribute('min-height', 45, 56)};
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const IframeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 3)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 3)};
`;

export const IframeLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const IframeContainer = styled.div`
  position: relative;
  opacity: ${({ $isLoaded }) => ($isLoaded ? 1 : 0)};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IframeCloseButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL,
})`
  align-self: flex-end;
  width: auto;
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadiuses.circle};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 5)};
  ${({ theme }) => theme.getFluidSpacing('padding', 'equal', 5)};

  svg {
    transform: scale(1.5);
  }

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
    color: ${getThemeColor('grey.100')};
  }
`;

export const StyledIFrame = styled.iframe`
  overflow: hidden;
  backface-visibility: hidden;
  width: 322px;
  height: min(100%, 756px);
  border: none;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  background-color: transparent;
`;

export const PlayIcon = styled(Play)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 3.2rem;
  transform: translate(-50%, -50%);
`;
