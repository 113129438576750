import PropTypes from 'prop-types';
import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getLinkDataFromUrl } from '@utils/urls';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import TeaserBlockBase, {
  Description,
  Label,
  Title,
} from '@common/components/TeaserBlockBase';
import PriceText from '@common/components/PriceText';
import { videoShape } from '@common/types/cms';
import {
  StoreNewsBanner,
  StoreNewsBannerText,
} from '@common/components/CMSComponents/NewsTeaser/NewsTeaser.styled';

const NewsTeaser = ({ value, index, reverseLayout, isStoreNews }) => {
  const { page, linkText } = value;
  const {
    title,
    image,
    lead,
    meta,
    categoryTitle,
    video,
    videoDesktop,
    ...otherPage
  } = page;

  const { t } = useTranslation();
  const newsRibbonText = `${t('highlightBadge.store.user', 'In Deiner Filiale')} ${otherPage?.store?.title}`;
  const linkData = getLinkDataFromUrl(meta.htmlUrl);

  const href = linkData?.urlObject;
  const as = linkData?.as;

  return (
    <TeaserBlockBase
      image={image}
      data-block-name="NewsTeaser"
      index={index}
      reverseLayout={reverseLayout}
      asHero={index === 0}
      link={{
        href,
        as,
      }}
      video={video}
      videoDesktop={videoDesktop}
      imageContainerAddon={
        isStoreNews && (
          <StoreNewsBanner>
            <StoreNewsBannerText variant="paragraph1">
              {[...Array(50).keys()].map(() => (
                <span key={page.id}>{newsRibbonText}</span>
              ))}
            </StoreNewsBannerText>
          </StoreNewsBanner>
        )
      }
    >
      {categoryTitle && <Label>{categoryTitle}</Label>}
      <Title>
        <PriceText>{title}</PriceText>
      </Title>
      {lead && (
        <Description clamp>
          <PriceText>{lead}</PriceText>
        </Description>
      )}
      <Link href={href} passHref as={as} legacyBehavior>
        <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
          {linkText || t('cmsBlock.newsTeaser.moreLinkText', 'Mehr erfahren')}
        </ButtonLink>
      </Link>
    </TeaserBlockBase>
  );
};

NewsTeaser.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    linkText: PropTypes.string,
    page: PropTypes.shape({
      categoryTitle: PropTypes.string,
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      label: PropTypes.string,
      lead: PropTypes.string,
      meta: PropTypes.shape({
        htmlUrl: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      video: videoShape,
      videoDesktop: videoShape,
    }).isRequired,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
  isStoreNews: PropTypes.bool,
};

NewsTeaser.defaultProps = {
  isStoreNews: false,
};

export default NewsTeaser;
