import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '@common/components/Modal';
import { useShouldRenderCarousel } from '@common/components/VouchersCarouselBase/VouchersCarouselBase.hooks';
import { Cross } from '@common/components/Icons';
import LoadingIndicator from '@common/components/LoadingIndicator';

import {
  CarouselWrapper,
  PlayIcon,
  StyledCarousel,
  StyledIFrame,
  Title,
  VoucherWrapper,
  Container,
  IframeWrapper,
  IframeCloseButton,
  IframeContainer,
  IframeLoader,
} from './TiktokEmbedCarousel.styled';

export const breakpoints = {
  1601: {
    slidesPerView: 3.75,
    spaceBetween: 20,
  },
  1201: {
    slidesPerView: 3.65,
    spaceBetween: 20,
  },
  881: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  501: {
    slidesPerView: 1.5,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.25,
    spaceBetween: 20,
  },
};

const fetchMultipleTiktokEmbed = urls => {
  const promises = urls.map(url =>
    fetch(`https://www.tiktok.com/oembed?url=${url}`).then(response =>
      response.json(),
    ),
  );

  return Promise.all(promises);
};

const TikTokItemsWrapper = ({ children, shouldRenderCarousel }) => {
  if (!shouldRenderCarousel) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <StyledCarousel breakpoints={breakpoints} withDesktopOverflow>
      {children}
    </StyledCarousel>
  );
};

const TiktokEmbedCarousel = ({ value: { items, heading } }) => {
  const carouselContainerRef = React.useRef(null);
  const [tikTokItems, setTiktokItems] = React.useState([]);
  const [selectedTikTokId, setSelectedTikTokId] = React.useState(null);
  const [iframeTopAligned, setIframeTopAligned] = React.useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = React.useState(false);
  const shouldRenderCarousel = useShouldRenderCarousel(items.length);

  const checkIfTopAligned = () => {
    setIframeTopAligned(window.innerHeight < 740);
  };

  React.useEffect(() => {
    fetchMultipleTiktokEmbed(items.map(({ videoUrl }) => videoUrl)).then(
      res => {
        setTiktokItems(res);
      },
    );

    const onResize = () => {
      checkIfTopAligned();
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [items]);

  const onCloseIframe = useCallback(() => {
    setSelectedTikTokId(null);
    setIsIframeLoaded(false);
  }, []);

  return (
    <Container ref={carouselContainerRef}>
      {tikTokItems.length > 0 && (
        <Fragment>
          <Title>{heading}</Title>
          <CarouselWrapper shouldRenderCarousel={shouldRenderCarousel}>
            <TikTokItemsWrapper shouldRenderCarousel={shouldRenderCarousel}>
              {tikTokItems.map(
                (
                  { thumbnail_url, title, thumbnail_width, thumbnail_height },
                  index,
                ) => {
                  const parts = items[index].videoUrl.split('/');
                  const videoId = parts[parts.length - 1];

                  return (
                    <VoucherWrapper
                      onClick={() => setSelectedTikTokId(videoId)}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <img
                        src={thumbnail_url}
                        alt={title}
                        width={thumbnail_width}
                        height={thumbnail_height}
                      />
                      {!(selectedTikTokId === videoId) && (
                        <PlayIcon width="14px" />
                      )}
                    </VoucherWrapper>
                  );
                },
              )}
            </TikTokItemsWrapper>
          </CarouselWrapper>

          {!!selectedTikTokId && (
            <Modal
              isOpen={!!selectedTikTokId}
              onClose={onCloseIframe}
              withCloseButton={false}
              mountOnEnter
              unmountOnExit
              appear
              exit
              enter
              timeout={500}
              shouldRenderFocusTrap={false}
              alwaysMounted
            >
              <IframeWrapper>
                {!isIframeLoaded && (
                  <IframeLoader>
                    <LoadingIndicator size={3} />
                  </IframeLoader>
                )}
                <IframeContainer $isLoaded={isIframeLoaded}>
                  <IframeCloseButton onClick={onCloseIframe}>
                    <Cross />
                  </IframeCloseButton>
                  <StyledIFrame
                    topAligned={iframeTopAligned}
                    allowTransparency="true"
                    title="tiktok"
                    src={`https://www.tiktok.com/embed/v2/${selectedTikTokId}`}
                    allow="encrypted-media;"
                    onLoad={() => setIsIframeLoaded(true)}
                  />
                </IframeContainer>
              </IframeWrapper>
            </Modal>
          )}
        </Fragment>
      )}
    </Container>
  );
};

TiktokEmbedCarousel.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        videoUrl: PropTypes.string,
      }),
    ),
    heading: PropTypes.string,
  }),
};

TikTokItemsWrapper.propTypes = {
  shouldRenderCarousel: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

TiktokEmbedCarousel.defaultProps = {
  value: {},
};

export default TiktokEmbedCarousel;
