export const ACCORDION_BLOCK_TYPE = 'accordion_block';
export const AREA_OF_OPERATION_LIST_BLOCK = 'area_of_operation_list_block';
export const CONTACT_INFORMATION_BLOCK_TYPE = 'contact_information_block';
export const FAVORITES_BLOCK_TYPE = 'favorites_block';
export const GALLERY_BLOCK_TYPE = 'gallery_block';
export const GIFT_TEASER_BLOCK_TYPE = 'gift_teaser_block';
export const IMAGE_BLOCK_TYPE = 'image_block';
export const IMAGE_TEXT_ALTERNATE_LIST_BLOCK_TYPE =
  'image_text_alternate_list_block';
export const LOOKBOOK_PRODUCTS_GRID_BLOCK_TYPE = 'product_list_block';
export const LOOKBOOK_PRODUCTS_SLIDER_CONTAINER_BLOCK_TYPE =
  'product_list_slider_container_block';
export const LOOKBOOK_TEASER_BLOCK_TYPE = 'lookbook_teaser_block';
export const NEWS_TEASER_BLOCK_TYPE = 'news_teaser_block';
export const STORE_NEWS_BLOCK_TYPE = 'store_news_block';
export const OFFER_LIST_BLOCK_TYPE = 'offer_list_block';
export const PAGE_TEASER_BLOCK_TYPE = 'page_teaser_block';
export const PAGE_CALL_TO_ACTION_BLOCK_TYPE = 'page_call_to_action_block';
export const PARAGRAPH_BLOCK_TYPE = 'paragraph_block';
export const PRESS_ARTICLES_LIST_BLOCK_TYPE = 'press_articles_list_block';
export const PRESS_STATEMENTS_LIST_BLOCK_TYPE = 'press_statements_list_block';
export const PRODUCT_CATEGORIES_SLIDER_BLOCK_TYPE =
  'product_categories_slider_block';
export const PRODUCTS_SLIDER_BLOCK_TYPE = 'product_slider_block';
export const PROMOTION_TEASER_BLOCK_TYPE = 'promotion_teaser_block';
export const STORE_FINDER_BLOCK_TYPE = 'store_finder_block';
export const STORE_INFORMATION_BLOCK_TYPE = 'store_information_block';
export const TABULAR_LIST_BLOCK_TYPE = 'tabular_list_block';
export const INFO_MODAL_VOUCHERS = 'chicoree-vip-explained';
export const PRODUCTS_OFFER_CAROUSEL = 'product_offer_type_carousel_block';
export const TRENDING_PRODUCTS_CAROUSEL = 'product_trendy_carousel_block';
export const PRODUCTS_NEW_ARRIVALS_CAROUSEL = 'new_arrivals_carousel_block';
export const PRODUCTS_BY_CATEGORIES_CAROUSEL =
  'product_category_carousel_block';
export const MOBILE_PLAN_BLOCK = 'mobile_plan_block';
export const MOBILE_FAQ_BLOCK = 'mobile_faq_block';
export const MOBILE_FACT_GRID_BLOCK = 'icon_fact_grid_block';
export const MOBILE_PLAN_LIST_BLOCK = 'mobile_plan_list_block';
export const GALLERY_BLOCK_WITH_TEXT = 'three_image_text_gallery_block';
export const PAGE_OR_LINK_TEASER_BLOCK_TYPE = 'page_or_link_teaser_block';
export const FLOWBOX_WIDGET_BLOCK = 'flowbox_widget_block';
export const BUTTON_BLOCK_TYPE = 'button_block';
export const PRODUCT_GRID_BLOCK_TYPE = 'product_grid_block';
export const BONS_CAROUSEL = 'value_vouchers_block';
export const COUPONS_CAROUSEL = 'goodie_vouchers_block';
export const COOKIE_BOT_BANNER_BLOCK = 'cookie_bot_banner_block';
export const COOKIE_BOT_DECLARATION_BLOCK = 'cookie_bot_declaration_block';
export const TIKTOK_EMBED_LIST_BLOCK = 'tiktok_embed_list_block';
export const PERSON_LIST_LOCK_BLOCK = 'person_list_lock';
export const FACTS_GRID_BLOCK = 'fact_grid_item_block';
export const YOUTUBE_EMBED_VIDEOS = 'youtube_embed_list_block';
export const TEXT_INFO_LIST_BLOCK = 'text_info_list_block';

export const CHICOREE_VIP_BENEFITS_PARTIAL_NAME = 'chicoree-vip-benefits';

export const CMS_COMPONENTS_NOT_REVERSING_LAYOUT = [
  PRODUCTS_OFFER_CAROUSEL,
  PRODUCTS_NEW_ARRIVALS_CAROUSEL,
  PRODUCT_CATEGORIES_SLIDER_BLOCK_TYPE,
  PRODUCTS_BY_CATEGORIES_CAROUSEL,
  FAVORITES_BLOCK_TYPE,
  FLOWBOX_WIDGET_BLOCK,
  BONS_CAROUSEL,
  COUPONS_CAROUSEL,
  TRENDING_PRODUCTS_CAROUSEL,
];

export const CMS_COMPONENTS_ALLOWED_EMPTY = [
  FAVORITES_BLOCK_TYPE,
  COUPONS_CAROUSEL,
  BONS_CAROUSEL,
  PRODUCT_CATEGORIES_SLIDER_BLOCK_TYPE,
];
